import React from 'react';
import loginStyles from '../../../stylesheets/components/login.module.scss';
import textStyles from '../../../stylesheets/components/text.module.scss';
import layoutStyles from '../../../stylesheets/components/layout.module.scss';
import {
  PreloadedQuery,
  graphql,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay';
import { useLoaderData } from 'react-router-dom';
import type {
  LoginHeaderQuery as LoginHeaderQueryType,
  LoginHeaderQuery$data as LoginHeaderQueryResponse,
} from './__generated__/LoginHeaderQuery.graphql';
import classNames from 'classnames';

export const LoginHeaderQuery = graphql`
  query LoginHeaderQuery($subdomain: String!) {
    publicLicense(subdomain: $subdomain) {
      name
      logoPath
    }
    publicBuilding(subdomain: $subdomain) {
      name
    }
  }
`;

const LoginHeader = () => {
  const loaderQueryReference = useLoaderData() as PreloadedQuery<
    LoginHeaderQueryType,
    LoginHeaderQueryResponse
  >;
  const [queryReference] = useQueryLoader<LoginHeaderQueryType>(
    LoginHeaderQuery,
    loaderQueryReference
  );

  const data = usePreloadedQuery(
    LoginHeaderQuery,
    queryReference as PreloadedQuery<LoginHeaderQueryType, LoginHeaderQueryType>
  );

  if (!data.publicLicense)
    return <div className={loginStyles.loginHeader}></div>;

  let headerTitle = '';
  let subHeaderTitle = '';
  if (data.publicBuilding && data.publicBuilding.name) {
    headerTitle = data.publicBuilding.name;
    if (data.publicLicense.name) subHeaderTitle = data.publicLicense.name;
  } else if (data.publicLicense.name) {
    headerTitle = data.publicLicense.name;
  }

  const subHeader =
    subHeaderTitle !== '' ? (
      <h5 className={classNames(layoutStyles.mt12, textStyles.colorSecondary)}>
        {subHeaderTitle}
      </h5>
    ) : null;
  let headerContent = <div></div>;
  if (!data.publicLicense.logoPath) {
    headerContent = (
      <>
        <span
          className={classNames(
            loginStyles.loginBusinessTitle,
            textStyles.colorMain
          )}
        >
          {headerTitle}
        </span>
        {subHeader}
      </>
    );
  } else {
    headerContent = (
      <>
        <img src={data.publicLicense.logoPath} className={layoutStyles.br6} />
        <span
          className={classNames(
            layoutStyles.block,
            textStyles.fwSb,
            textStyles.colorMain
          )}
        >
          {headerTitle}
        </span>
        {subHeader}
      </>
    );
  }

  return <div className={loginStyles.loginHeader}>{headerContent}</div>;
};

export default LoginHeader;
